.article-wrapper {
  display: flex;
  flex-direction: row;
  margin: 25px;
  margin-left: 150px;
  margin-right: 150px;
  height: 100%;
  min-height: calc(100vh - 7rem);
}

.article-info {
  display: flex;
  flex-direction: column;
}

.article-content {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 25px;
  padding: 25px;
  max-width: 100%;
  word-break: break-word;
}

.bg-info {
  background-color: #99c4d9 !important;
}

.bigSubText {
  font-weight: 700;
}

.article-content h2 {
  font-size: 48px;
}

.author-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.author-info h2 {
  color: #ea86a4;
}

.article-author,
.article-feedback,
.article-feedback-input {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 480px;
  padding: 15px;
  gap: 40px;
  padding-left: 25px;
}

.article-feedback,
.article-feedback-input {
  margin-top: 15px;
}

.article-feedback-input {
  flex-direction: column;
  padding-top: 25px;
}

.article-author-area {
  display: flex;
  flex-direction: column;
}

.feedback-author {
  display: flex;
}

.feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback h2 {
  font-size: 1em;
}

.feedback-author-details {
  margin-bottom: 15px;
  line-height: 0.5em;
  font-weight: lighter;
}

.feedback-content {
  padding-right: 5px;
  word-break: break-all;
}

.author-avatar,
.feedback-avatar {
  display: flex;
  justify-content: center;
  height: 150px;
  width: 150px;
  margin-right: 15px;
  border-radius: 100%;
  background-color: #ea86a4;
  color: white;
}

.author-avatar {
  background-size: cover !important;
  background: url('https://media-exp1.licdn.com/dms/image/C4D03AQHPOytFbjgcmQ/profile-displayphoto-shrink_200_200/0/1639681037928?e=1648684800&v=beta&t=rdmNs0NRYHgK7WjLbeUklovkVe_AoMzfR4uCdmRjNy8');
}

.feedback-avatar {
  height: 15em;
  width: 15em;
  min-width: 15em;
  font-size: 0.35em;
  background-color: #95dbdb;
}

.author-avatar span,
.feedback-avatar span {
  font-size: 5em;
  align-self: center;
}

small {
  font-weight: lighter;
}

.article-socials .social-icon {
  margin-right: 5px !important;
}

.comment-date-small {
  display: none;
}

.sub-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.author-title-headline {
  display: none;
  margin-bottom: 15px;
  font-weight: 100;
}

.comments-headline {
  display: none;
  margin-top: 15px;
  font-weight: 100;
}

@media only screen and (max-width: 955px) {
  .article-wrapper {
    flex-wrap: wrap;
    gap: 25px;
    margin-left: 25px;
    margin-right: 25px;
  }
  .article-author,
  .article-feedback,
  .article-feedback-input {
    width: 100%;
  }
  .article-content {
    margin-right: 0;
  }
  .author-avatar,
  .feedback-avatar {
    display: none;
  }
  .comment-date-big {
    display: none;
  }
  .comment-date-small {
    display: inline;
  }
  .author-title-headline,
  .comments-headline {
    display: inline;
  }
}
