@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Rodrigues';
  src: local('Rodrigues'), url(../src/assets/fonts/Rodrigues/webfont/Rodrigues.woff) format('woff');
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato-Light'), url(../src/assets/fonts/Lato/Lato-Light.ttf) format('ttf');
}

html {
  width: 100%;
  height: 100%;
  word-break: normal;
}

body {
  background-color: #fafafa;
  box-sizing: border-box;
  color: #767676;
  margin: 0 !important;
  overflow-x: hidden;
  overflow-y: auto !important;
  padding: 0 !important;
  font-smooth: always;
  letter-spacing: 0.2px;
  line-height: 1.4;
  font-family: 'Lato-Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
blockquote,
form,
h1,
h2,
h3,
h4,
h5,
li,
ol,
p,
span,
table,
td,
ul,
strong {
  font-weight: normal;
}

p {
  font-size: 19px !important;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
}

table {
  width: 100%;
}

html,
body {
  height: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

@media only screen and (max-width: 955px) {
  p {
    font-size: 16px !important;
  }
}
