@import '../../vars.scss';

.modal-header {
  background-color: var(--quaternary_color);
  color: #ffffff;
  text-transform: uppercase;
}

.passwort-reset-link {
  color: var(--quaternary_color);
}

.passwort-reset-link:hover {
  color: var(--secondary_color);
  transition: color 1s;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.text-block {
  font-size: 1rem;
  width: 20rem;
}

.text-block h2 {
  font-family: 'Rodrigues';
  font-size: 3rem;
  color: var(--secondary_color);
  font-weight: bold;
}

.text-picture img {
  height: 25rem;
  width: 30rem;
}

.buttonSet {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@media only screen and (max-width: 411px) {
  .buttonSet Button {
    flex: 1 1 auto;
  }
}

Button:active {
  border: 0;
}
