@import '../../vars.scss';

button {
  background-color: var(--quaternary_color);
  font-size: 1.2rem;
  font-weight: thin;
  text-transform: uppercase;
  color: white;
  border: 0;
  padding: 15px;
  width: 100%;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

button:hover {
  background-color: var(--secondary_color);
  transition: background-color 1s;
}
