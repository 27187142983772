@import '../../vars.scss';

.block {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7em;
  gap: 15px;
}

.underneath {
  padding-top: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  gap: 0;
}

.quote-text {
  font-size: 2em;
  font-style: italic;
  font-family: 'Lato';
}

.author {
  font-size: 1rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 955px) {
  .quote-text {
    font-size: 1.5em;
    text-align: center;
  }
}
