.alert-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.alert-wrapper AlertDialog {
  flex: 1 1 auto;
}

.newsletter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  min-height: calc(100vh - 15rem);
  gap: 35px;
  margin-bottom: 25px;
}

.newsletter-image img {
  filter: brightness(150%);
  width: 800px;
  height: 600px;
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}

.newsletter-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.newsletter-text {
  text-align: center;
  height: 100%;
}

.newsletter-text h2 {
  font-family: 'Rodrigues';
  font-size: 45px;
  color: var(--secondary_color);
  font-weight: bold;
}

.newsletter-text p {
  text-align: center;
  font-size: 16px;
}

.newsletter-card {
  width: 80%;
}

@media only screen and (max-width: 955px) {
  .newsletter-image img {
    width: 100%;
    height: 100%;
  }
  .newsletter-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}
