.slideshow {
  //margin-top: 9rem;
  width: 100%;
  height: calc(100vh - 9rem);
}

.slideshow-item {
  width: 100%;
  height: calc(100vh - 9rem);
  background-size: cover !important;
  object-fit: cover;
}

.mobile-img {
  display: none !important;
}

@media only screen and (max-width: 955px) {
  .slideshow-item {
    max-height: 100% !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
  .slideshow {
    max-height: 100% !important;
  }
  .slideshow-container {
    max-height: 100% !important;
  }
  .mobile-img {
    display: block !important;
  }
  .static-item {
    display: none !important;
  }
}
