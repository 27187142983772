@import '../../../vars.scss';

.thumbnail {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.thumbnail:hover {
  transform: scale(1.02);
  transition: transform 0.5s ease;
  z-index: 1;
}

.thumbnail:hover > .preview {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.05);
  transition: transform 1.2s;
  z-index: 2;
}

.numberCircle {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  margin-right: 0.25em;

  background: #95dbdb;
  border: 2px solid;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.link-without-hover:hover {
  color: var(--secondary_color);
}

.preview {
  display: none;
  z-index: 2;
}

.preview-body-wrapper {
  display: flex;
}

.preview-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.preview-action {
  font-size: 2em;
  width: 100%;
  text-align: right;
  align-self: flex-end;
  color: var(--secondary_color);
}

.preview-action a {
  color: inherit;
}

.play-button:hover {
  color: var(--primary_color);
  transform: scale(1.03);
  transition: transform 1s;
}
