@import '../vars.scss';

.video-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 9rem);
}

.react-player-wrapper {
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}

.react-player {
  max-width: none;
  max-height: none;
  color: #0000;
}

iframe {
  width: 100%;
  height: 100%;
}

.download-link {
  color: var(--secondary_color);
}

.download-link:hover {
  color: var(--primary_color);
  transition: 1s color;
}

.video-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px;
  gap: 50px;
  flex-wrap: wrap;
}

.workbook-wrapper {
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.workbook-img {
  height: 280px;
  width: 560px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 955px) {
  .workbook-img {
    height: 100%;
    width: 100vw;
    margin-bottom: 15px;
  }
  .workbook-wrapper {
    width: 100vw;
  }
  .workbook-wrapper b {
    text-align: center;
  }
  .workbook-wrapper button {
    width: 90vw !important;
  }
}
