:root {
  --magic-number: 0.09719; /* tan(11°)/2 */
  --content-width: 100vw;
  --skew-padding: calc(var(--content-width) * var(--magic-number));
  --angle: -11deg;
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}

.course-thumbnail {
  height: calc(100vh - 9rem);
  width: 100vw;
}

.course-thumbnail img {
  height: calc(100vh - 9rem);
  width: 100vw;
}

.course-wrapper {
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-size: 16px;
}

.course-img {
  height: 100%;
  width: 3em;
}

.course-image-beside-text {
  display: flex;
  flex-direction: row;
  margin-top: 2.5em;
  align-items: center;
}

.course-text {
  text-align: justify;
  width: 800px;
}

.course-intern-wrapper {
  margin: 25px;
}

.course-title {
  font-size: 48px;
  font-weight: 300;
  text-align: center;
}

.course-headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  text-align: center;
}

.course-intro-block {
  color: white;
  font-family: 'Rodrigues';
  font-size: 112px;
}

.marker {
  padding: 30px 0 8px;
  position: relative;
}

.marker:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 8px;
  width: 110%;
  height: 14px;
  transform: skew(-12deg) translateX(-50%);
  background-color: #efccc5;
  z-index: -1;
}

.marker-two {
  padding: 30px 0 8px;
  position: relative;
}

.marker-two:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 8px;
  width: 110%;
  height: calc(100% - 1em);
  transform: translateX(-50%);
  background-color: #efccc5;
  z-index: -1;
}

.invisible-text {
  color: rgba(0, 0, 0, 0.1);
  &:hover {
    color: black;
    transition: color 1s;
  }
}

.two-columns {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.blog-content {
  width: 400px;
  padding: 32px;
}

.course-list {
  font-style: italic;
  font-size: 1.2em;
  list-style: none;

  li:before {
    font-size: 1.2em;
    line-height: 1.2em;
    color: #efccc5;
    content: '- ';
  }
}

.numerated-headline {
  font-family: 'Rodrigues';
  color: black;
  font-size: 3.5em;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ticked-list {
  list-style: none;
  text-align: left;

  li:before {
    font-family: 'Rodrigues';
    font-size: 1.5em;
    line-height: 1.5em;
    color: #efccc5;
    content: '✓ ';
  }
}

.content {
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 24px;
  position: relative;
}

.static {
  transform: skewY(11deg);
  margin-top: 30px;
}

.bg-white {
  background-color: #ffffff;
  color: #000000;
}

.bg-white h1 {
  color: #000000;
}

p {
  margin-top: 35px;
  font-size: 1.2em;
  text-align: justify;
}

.box-headline {
  text-align: center;
  margin: 0 auto 16px;
  padding: 0 16px;
  max-width: 672px;
  line-height: 1.2;
  font-size: 3em;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
}

ul {
  font-style: italic;
}

.right-centered-button {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  width: 100%;
  margin-bottom: 25px;
}

.left-centered-button {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  width: 100%;
  margin-bottom: 25px;
}

.ticket-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 19.2px;
}

.course-overview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 80px;
  gap: 64px;
  flex-wrap: wrap;
}

@media screen and (min-width: 42em) {
  :root {
    --content-width: 42em;
  }
}

@media only screen and (max-width: 955px) {
  .course-text {
    width: 100% !important;
  }
  .course-intro-block {
    font-size: 55px !important;
  }
  .course-image-beside-text {
    flex-direction: column;
    justify-content: center;
  }
  .course-image-beside-text span {
    margin: 0 !important;
  }
  .course-img {
    width: 100%;
    margin-bottom: 25px;
  }
  .numerated-headline {
    flex-direction: column !important;
  }
  .blog-content {
    padding: 0 !important;
  }
  .course-overview {
    margin: 5px;
    margin-top: 50px;
  }
  .mobile-margin {
    margin-bottom: 30px;
  }
}
