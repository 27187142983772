.pl {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:hover > .controlsContainer {
    opacity: 1;
    transition: opacity 1s;
  }
}

/* Video */

.video {
  width: 100%;
  height: 100%;
}

/* Controls */

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 80vh;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 2s;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 100%;
}

.controlsIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

.controlsIcon--small {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 10rem;
  margin-right: 10rem;
}

/* The time controls section */

.timecontrols {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.time_progressbarContainer {
  background-color: gray;
  border-radius: 15px;
  width: 75vw;
  height: 5px;
  z-index: 30;
  margin: 0 20px;
}

.time_progressBar {
  border-radius: 15px;
  background-color: #99c4d9;
  height: 100%;
}

.controlsTime {
  color: white;
}
