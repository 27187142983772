@import '../../vars.scss';

header {
  //position: fixed;
  //top: 0;
  //z-index: 1;
  width: 100%;
  background-color: white;
}

.burger-info {
  display: none;
  margin-right: 15px;
}

.burger-menu {
  display: none;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #99c4d9;
  border-radius: 5px;
}

.burger-menu ul {
  list-style: none;
}

.text-info {
  color: #99c4d9 !important;
  font-size: 50px;
}

.burger-menu ul a li {
  text-align: center;
  height: 100%;
  padding: 10px;
  font-weight: 700;
  border: 1px solid #99c4d9;
  color: #99c4d9;

  &:hover {
    color: #ffffff;
    background-color: #99c4d9;
    border-color: #ffffff;

    & > a {
      color: #ffffff;
    }
  }
}

.burger-menu ul li a {
  text-decoration: none;
  height: 100%;
  color: inherit;
}

.regular-menu {
  height: 9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  border-bottom: 4px var(--quaternary_color);
  padding-right: 15px;
  padding-left: 15px;
}

.active {
  color: var(--secondary_color) !important;
}

.active-with-burger {
  background-color: var(--quaternary_color);
  color: white !important;
  border-color: white !important;
}

@media only screen and (max-width: 976px) {
  .burger-info {
    display: block;
  }
  .regular-menu {
    display: none;
  }
  .burger-menu {
    display: block;
  }
}

nav {
  height: 9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  border-bottom: 4px var(--quaternary_color);
  padding-right: 15px;
  padding-left: 15px;
}

.logo {
  width: 12rem;
  height: 5rem;
}

.navigation-links {
  display: flex;
}

.navigation-links a {
  text-decoration: none;
  text-transform: uppercase;
  color: #807e7e;
  font-size: 1.2rem;
  font-weight: thin;
  line-height: 2em;
  letter-spacing: 0.12em;
}

.navigation-links a:hover {
  color: var(--secondary_color);
  transition: color 1s;
}

.company-logo {
  flex-grow: 1;
}

.openDropdown {
  color: var(--secondary_color) !important;
}
