@import '../../vars.scss';

.portrait-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  gap: 30px;
  margin: 0;
  margin-bottom: 5rem;
  outline: 0;
  margin-top: 5rem;
}

.image-wrapper {
  width: 50%;
  text-align: center;
  margin-right: 5em;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.polaroid > img {
  max-width: 100%;
  height: auto;
}

.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}

.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.4s;
}

.item:nth-of-type(2n + 1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}

.item:nth-of-type(2n + 1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.item:nth-of-type(2n + 2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}

.item:nth-of-type(2n + 2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.4);
}

.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg);
  transition: all 0.4s;
  margin-right: 5em;
}

.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 60%;
  width: 60%;
  bottom: 0%;
  right: 10%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
  transition: all 0.35s;
}

.portrait-img {
  width: 100%;
  height: 100%;
}

.vertical-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.img {
  object-fit: cover;
}

.portrait-text {
  text-align: justify;
  height: 100%;
  width: 25%;
  font-size: 16px;
}

.portrait-list {
  font-style: italic;
  text-align: left;
  list-style-type: numberic;
  font-weight: 300;
}

@media only screen and (max-width: 955px) {
  .image-wrapper {
    display: none;
  }
  .portrait-text {
    margin: 0 !important;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
  .portrait-list {
    text-align: center;
    list-style: none;
  }
}

.my-mission {
  font-size: 20px;
  font-weight: bold;
  color: var(--secondary_color);
}

.link {
  color: var(--secondary_color);
}

.link:hover {
  color: var(--primary_color);
}

.portrait-text h2 {
  font-family: 'Rodrigues';
  font-size: 45px;
  color: var(--secondary_color);
  font-weight: bold;
}

li {
  margin-bottom: 0.8rem;
}
