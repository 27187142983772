@import '../../vars.scss';

.experience-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 40px;
  gap: 25px;
  margin-bottom: 2rem;
}

.experience-wrapper h2 {
  width: 100%;
  font-size: 80px;
  color: var(--quaternary_color);
  font-family: 'Rodrigues';
  text-align: center;
}

.card-bodies {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exp-img {
  height: 400px;
}

.experience-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.exp-card-title {
  font-size: 25px;
  text-align: center;
  font-weight: 100;
}

.exp-card-subtitle {
  font-size: 20px;
  color: #99c4d9;
  text-align: center;
}

.e-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.e-icon i {
  font-size: 5em;
}

.e-icon i:hover {
  transform: scale(1.05);
  transition: transform 1s;
}

.exp-card {
  width: 20rem;
  border: 1px solid white;
  box-shadow: 0 2.1rem 2rem rgba(0, 0, 0, 0.05);
}

.exp-card:hover {
  transform: scale(1.025);
}

@media only screen and (max-width: 955px) {
  .exp-img {
    height: 200px !important;
  }
}
