.aboutus-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  align-items: center;
  justify-content: center;
  margin-top: 5em !important;
  gap: 10em;
  font-size: 16px;
}

.aboutme-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.about-us-thumbnail {
  width: 100%;
  height: calc(100vh - 9rem);
  background-size: cover;
  object-fit: cover;
}

.about-us-text-block p {
  margin: 1em;
  font-size: 1.4rem;
}

.about-us-text-block {
  text-align: justify;
  font-size: 1rem;
  width: 32rem !important;
  margin: 0;
  padding: 1em;
  border-radius: 1em;
}

.newsletterButton {
  background-color: #ea86a4 !important;
  border-color: #ea86a4 !important;
}

.headline {
  text-align: center;
  margin-top: 15px;
  font-family: 'Rodrigues';
  font-size: 6rem;
  color: var(--secondary_color);
}

.polaroid {
  width: 100% !important;
}

.image-wrappers {
  width: 100%;
  text-align: center;
  margin-right: 5em;
}

.aboutus-portrait-img {
  width: 450px;
  height: auto;
}

.text-block h2 {
  font-family: 'Rodrigues';
  font-size: 3rem;
  color: var(--secondary_color);
  font-weight: bold;
}

.text-picture img {
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;
  filter: brightness(110%);
}

.text-picture {
  width: 50em;
  margin-right: 15px;
}

.aboutus-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  color: white;
  gap: 5px;
  background-color: #ea86a4;
  margin-bottom: 128px;
}

.centered-wrapper {
  width: 600px;
  text-align: center;
}

@media only screen and (max-width: 955px) {
  .text-picture {
    display: none;
  }
  .about-us-thumbnail {
    background-position: center center;
  }
  .headline {
    margin-left: 10px;
    margin-right: 10px;
  }
  .aboutus-wrapper {
    gap: 0;
    margin-bottom: 0;
  }
  .aboutus-portrait-img {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 32px;
  }
  .aboutus-block {
    margin: 0;
    text-align: center;
    padding: 32px;
  }
  .aboutus-block h2 {
    font-weight: bold;
  }
  .centered-wrapper {
    width: 100%;
    padding: 25px;
  }
}

.image-with-text-block img {
  width: 60em;
  height: 40em;
}
