.attention-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.attention-text-block {
  width: 27rem !important;
  font-weight: 300;
  padding: 15px;
  background-color: rgba(0,0,0,0.005);
}

.newsletterButton {
  background-color: #ea86a4 !important;
  border-color: #ea86a4 !important;
}

.attention-text-block h2 {
  font-family: 'Rodrigues';
  font-size: 70px;
  color: var(--secondary_color);
  font-weight: bold;
}

.attention-pic {
  width: 30%;
  filter: brightness(110%);
}

@media only screen and (max-width: 955px) {
  .text-picture {
    display: none;
  }
  .attention-pic {
    display: none;
  }
  .attention-text-block {
    margin-left: 5%;
    margin-right: 5%;
  }
}
