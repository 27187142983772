@import '../../vars.scss';

.category {
  margin-bottom: 25px;
}

.category-name {
  background-color: var(--primary_color);
  height: 3.5rem;
  line-height: 3.5rem;
  vertical-align: middle;
  color: white;
  text-transform: uppercase;
  padding-left: 15px;
}

.thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 955px) {
  .thumbnails {
    justify-content: center;
  }
}
