@import '../../vars.scss';

footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--primary_color);
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 15px;
}

footer ul {
  display: inline-block;
  list-style: none;
}

.impressum {
  display: flex;
  gap: 5px;
  text-transform: uppercase;
}

footer a {
  text-decoration: none;
  color: #ffffff;
}

.copyright {
  color: #ffffff;
  text-transform: uppercase;
}

.impressum a:hover {
  color: #ffffff;
  font-weight: bold;
}
